<template>
  <v-card flat class="rounded-10">
    <v-card-title class="text-center py-5">
      <v-row align="center" justify="center">
        <v-card flat width="180" height="150" v-if="mdAndUp">
          <v-img
            :lazy-src="settings.loader"
            height="100"
            contain
            :src="settings.squareLogo"
          ></v-img>
          <v-card-actions class="d-flex">
            <h2
              class="
                fs-20
                align-self-center
                mx-auto
                primary--text
                font-weight-bold
              "
            >
              {{ settings.SiteName }}
            </h2>
          </v-card-actions>
        </v-card>
        <v-icon x-large class="mx-5" color="primary" v-if="mdAndUp">
          mdi-arrow-left-right-bold
        </v-icon>
        <v-card flat width="180" height="150">
          <v-img
            :lazy-src="settings.loader"
            contain
            height="100"
            :src="connectApp.logo"
          ></v-img>
          <v-card-actions class="d-flex">
            <h2
              class="
                fs-20
                align-self-center
                mx-auto
                primary--text
                font-weight-bold
              "
            >
              {{ connectApp.name }}
            </h2>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-card-title>
    <v-card-text>
      <v-row dense class="mb-1">
        <p class="text-center col-md-8 mx-auto fs-20 py-5">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero quae
          eaque eius id cupiditate, reiciendis molestias velit aperiam
          dignissimos doloremque atque enim sit quod reprehenderit suscipit cum
          tempora iste quas.
        </p>
      </v-row>
      <v-row dense class="py-3" align="center" justify="center">
        <v-btn x-large color="primary" class="text-none" :disabled="!available">
          Sign In with {{ connectApp.name }}
        </v-btn>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters('app', ['connects', 'allowedConnects']),
    connectApp() {
      return this.connects.find((i) => i.id === 'canva')
    },
    available() {
      return this.allowedConnects.includes(this.connectApp.id)
    }
  }
}
</script>

<style lang="scss" scoped></style>
